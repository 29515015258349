import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import LoadingScreen from "components/LoadingScreen";
import LayoutV1 from "layouts/layout-v1/DashboardLayout";
import LayoutV2 from "layouts/layout-v1/NaverLayout";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
}; // dashboards

const Survery = Loadable(lazy(() => import("./pages/survey")));
const Ceoland = Loadable(lazy(() => import("./pages/ceoland")));

const Error = Loadable(lazy(() => import("./pages/404")));

const routes = () => {
  return [
    {
      path: "error",
      element: <Error />,
    },
    {
      path: "/",
      element: <LayoutV2 />,
      children: ceolandRoutes,
    },
  ];
};

const ceolandRoutes = [
  {
    path: "/*",
    element: <Ceoland />,
  },
  {
    path: "/",
    element: <Ceoland />,
  },
];
export default routes;
