import { Fragment, useState } from "react";
import { Outlet, useLocation } from "react-router";
import { Grid, useMediaQuery, useTheme } from "@mui/material";

const NaverLayout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation(); // Get the current location

  const [showSideBar, setShowSideBar] = useState(true);
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);

  const customStyle = {
    width: `calc(100% - ${showSideBar ? "260px" : "0px"})`,
    marginLeft: showSideBar ? "260px" : "0px",
  };

  // Check if the current route is '/naver/wrtn'
  const hideFooter = location.pathname === "/naver/wrtn";

  return (
    <Fragment>
      <Fragment>{children || <Outlet />}</Fragment>
    </Fragment>
  );
};

export default NaverLayout;
